<template>
  <div class="ab-setup">
    <el-table :data="tableData">
      <el-table-column prop="week" label="星期" width="80px"></el-table-column>
      <el-table-column prop="abFlag" label="A/B套" width="80px"></el-table-column>
      <el-table-column prop="workStations" label="临时工段" :formatter="formatterWorkStations"></el-table-column>
      <el-table-column prop="expireTime" label="到期时间" width="160px"></el-table-column>
      <el-table-column label="操作" class-name="operate-col" width="200px">
        <template #default="{row}">
          <el-button @click="handleSetTemp(row)">设置临时工段</el-button>
          <el-button @click="handleEdit(row)">修改</el-button>
          <!-- <el-button @click="handleDelete(row)" class="del">删除</el-button> -->
        </template>
      </el-table-column>
    </el-table>

    <!-- 编辑弹框 -->
    <el-dialog v-model="editDialogVisible" width="500px" @close="cancelEdit('editFormRef')">
      <template #title>
        <h1>{{curEditLabel.zh}}</h1>
        <p>{{curEditLabel.en}}</p>
      </template>
      <el-form :model="editFormData" ref="editFormRef" class="edit-form" :rules="editFormRules">
        <el-form-item label="A/B套" prop="abFlag">
          <el-select v-model="editFormData.abFlag" placeholder="请选择">
            <el-option
              v-for="item in abList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="cancelEdit('editFormRef')">取 消</el-button>
        <el-button class="active" @click="confirmEdit('editFormRef')">确 认</el-button>
      </template>
    </el-dialog>

    <!-- 设置临时工段弹框 -->
    <el-dialog v-model="setTempDialogVisible" width="500px" @close="cancelSetTemp('setTempFormRef')">
      <template #title>
        <h1>设置临时工段</h1>
        <p>Set up temporary section</p>
      </template>
      <el-form :model="setTempFormData" ref="setTempFormRef" class="edit-form set-temp" :rules="setTempFormRules">
        <el-form-item label="临时工段" prop="workStations">
          <el-select v-model="setTempFormData.workStations" placeholder="请选择" multiple>
            <el-option
              v-for="item in workStationList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="生效时间" prop="effectTime">
          <el-date-picker v-model="setTempFormData.effectTime" type="date"
                          format="YYYY-MM-DD"
                          value-format="YYYY-MM-DD"
                          placeholder="请选择"></el-date-picker>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="cancelSetTemp('setTempFormRef')">取 消</el-button>
        <el-button class="active" @click="confirmSetTemp('setTempFormRef')">确 认</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { sys, common } from '@/api'
import { toRaw } from '@vue/reactivity'

export default {
  name: 'AbSetup',
  data() {
    return {
      tableData: [], // 表格数据
      abList: [
        {
          label: 'A',
          value: 'A',
        },
        {
          label: 'B',
          value: 'B',
        }
      ],
      curEditLabel: {
        zh: '',
        en: '',
      },
      editDialogVisible: false, // 修改弹框visible
      editFormData: { // 修改表单
        abFlag: ''
      },
      editFormRules: { // 修改表单的校验
        abFlag: { required: true, message: '请选择', trigger: 'change' },
      },

      setTempDialogVisible: false, // 设置临时工段弹框visible
      setTempFormData: { // 设置临时工段表单
        workStations: [],
        effectTime: ''
      },
      setTempFormRules: { // 设置临时工段表单校验
        // workStations: { required: true, message: '请选择', trigger: 'change' },
        // effectTime: { required: true, message: '请选择', trigger: 'change' },
      },
      workStationList: [], // 工段列表
      pickerOptions: {
        disabledDate(v) {
          return v.getTime() < new Date().getTime() - 86400000;
        }
      }
    }
  },
  mounted() {
    this.loadTableData()
    this.getWorkStationList()
  },
  methods: {
    // 获取列表数据
    async loadTableData() {
      const {code, msg, data} = await sys.getABSetupList()
      if (code === 0) {
        this.tableData = data || []
      } else {
        this.$message.error(msg)
      }
    },
    // 获取工段列表
    async getWorkStationList() {
      const {code, msg, data} = await common.queryDictWorkStation()
      if (code === 0) {
        this.workStationList = data || []
      } else {
        this.$message.error(msg)
      }
    },
    // 格式化临时工段
    formatterWorkStations(row, column, cellValue) {
      let val = toRaw(cellValue)
      if(Array.isArray(val)) {
        let temp = val.map(item => item.label)
        return temp.toString()
      }
      return cellValue
    },
    // 点击设置临时工段
    handleSetTemp(row) {
      let wsVal = toRaw(row.workStations)
      let workStations = []
       if(Array.isArray(wsVal)) {
        workStations = wsVal.map(item => item.value)
      }
      this.setTempFormData = {
        id: row.id,
        workStations,
        effectTime: row.effectTime
      }

      this.setTempDialogVisible = true
    },
    // 确认设置临时工段
    confirmSetTemp(formName) {
      this.$refs[formName] && this.$refs[formName].validate && this.$refs[formName].validate(async (valid) => {
        if(valid) {
          const {code, msg} = await sys.setTemp(this.setTempFormData)
          if(code === 0) {
            this.setTempDialogVisible = false
            this.$message.success(`操作成功`)
            this.loadTableData()
          } else {
            this.$message.error(msg)
          }
        }
      })
    },
    // 取消设置临时工段
    cancelSetTemp(formName) {
      this.$refs[formName].resetFields()
      this.setTempDialogVisible = false
    },
    // 点击编辑
    handleEdit(row) {
      this.editFormData = {...row}
      this.curEditLabel.zh = '修改'
      this.curEditLabel.en = 'Edit'
      this.editDialogVisible = true
    },
    // 确认修改
    confirmEdit(formName) {
      this.$refs[formName] && this.$refs[formName].validate && this.$refs[formName].validate(async (valid) => {
        if(valid) {
          let params = { ...this.editFormData }
          const {code, msg} = await sys.editABSetup(params)
          if(code === 0) {
            this.editDialogVisible = false
            this.$message.success(`${this.curEditLabel.zh}成功`)
            this.loadTableData()
          } else {
            this.$message.error(msg)
          }
        }
      })
    },
    // 取消修改
    cancelEdit(formName) {
      this.$refs[formName].resetFields()
      this.editDialogVisible = false
    },
    // 点击删除
    handleDelete({id}) {
      this.$confirm('此操作将删除该项, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const {code, msg} = await sys.delABSetup({id})
        if(code === 0) {
          this.$message.success(`删除成功`)
          this.loadTableData()
        } else {
          this.$message.error(msg)
        }
      }).catch(() => {
        console.info('取消删除操作')
      })
    },
  }
}
</script>

<style lang="scss" scoped>
@import "./style.module.scss";
</style>