<template>
  <div class="sys-config list-page">
     <div class="search-box">
      <div class="title">
        <h1>系统配置</h1>
        <p>System Configuration</p>
      </div>
      <div class="body">
        <div class="btn-box">
          <template v-if="userRole != 'STATION_ADMIN'">
            <el-button @click="changeType('WORK_STATION')" :class="{'active': curType == 'WORK_STATION'}">工段</el-button>
            <el-button @click="changeType('CLASS_GROUP')" :class="{'active': curType == 'CLASS_GROUP'}">班组</el-button>
            <el-button @click="changeType('EMP_STATION')" :class="{'active': curType == 'EMP_STATION'}">工位</el-button>
            <el-button @click="changeType('ASSET_STATUS')" :class="{'active': curType == 'ASSET_STATUS'}">资产状态</el-button>
          </template>
          <el-button @click="changeType('ASSET_TYPE')" :class="{'active': curType == 'ASSET_TYPE'}">资产类型</el-button>
          <el-button @click="changeType('AB_SETUP')" :class="{'active': curType == 'AB_SETUP'}">AB套配置</el-button>
        </div>
        <div class="list-box">
          <div class="global-operate-block">
            <div class="title">
              <h1>查询结果</h1>
              <p>search result</p>
            </div>
            <div class="global-operate-btn-box">
              <el-button @click="handleMultiQRCode" v-if="curType == 'EMP_STATION'"><img src="@/assets/imgs/qr-code.png" />批量下载二维码</el-button>
              <el-button class="active" @click="handleAdd" v-if="userRole != 'STATION_ADMIN' && curType != 'AB_SETUP'"><img src="@/assets/imgs/add.png" />新增配置</el-button>
              <template v-if="curType == 'AB_SETUP'">
                <span>当前换班时间点：{{curABHandoverPoint || '-'}}</span>
                <el-button class="active set-ab-handover-point" @click="handleABHandoverPoint"><img src="@/assets/imgs/setup.png" />设置换班时间点</el-button>
              </template>
            </div>
          </div>

          <el-table 
            v-show="curType != 'AB_SETUP'"
            :data="tableData" 
            ref="multipleTable" 
            row-key="id"
            @select="handleSelect" 
            @select-all="handleSelectAll">
            <el-table-column type="selection" width="50" v-if="curType == 'EMP_STATION'" :reserve-selection="true"></el-table-column>
            <el-table-column label="序号" type="index" :index="index => index + 1"></el-table-column>
            <el-table-column prop="value" label="类型编号"></el-table-column>
            <el-table-column prop="label" label="类型名称"></el-table-column>
            <el-table-column prop="virtualFlag" label="是否虚拟工段" v-if="curType == 'WORK_STATION'"  :formatter="formatterVirtualFlag"></el-table-column>

            <el-table-column prop="parentLabel" label="工段" v-if="curType == 'CLASS_GROUP'"></el-table-column>
            <el-table-column prop="grandParentLabel" label="工段" v-if="curType == 'EMP_STATION'"></el-table-column>
            <el-table-column prop="parentLabel" label="班组" v-if="curType == 'EMP_STATION'"></el-table-column>
            <el-table-column prop="empStationType" label="工位类型" v-if="curType == 'EMP_STATION'" :formatter="formatterEmpStationType"></el-table-column>

            <el-table-column v-if="curType == 'ASSET_STATUS'" prop="needTpm" label="是否点检" :formatter="formatterNeedTpm"></el-table-column>
            <el-table-column v-if="curType == 'ASSET_STATUS'" prop="needAssetPts" label="是否累计拧紧次数" :formatter="formatterNeedAssetPts"></el-table-column>
            <el-table-column v-if="curType == 'ASSET_STATUS'" prop="tpmTaskBelong" label="点检任务归属人" :formatter="formatterTpmTaskBelong"></el-table-column>

            <el-table-column v-if="curType == 'ASSET_TYPE'" prop="isShow" label="是否首页显示" :formatter="formatterIsShow"></el-table-column>
            <el-table-column v-if="curType == 'ASSET_TYPE'" prop="isShowTpm" label="是否显示PTS" :formatter="formatterIsShowPts"></el-table-column>
            <el-table-column v-if="curType == 'ASSET_TYPE'" prop="isAb" label="A/B套工具">
              <template #default="{row}">
                <span>{{row.isAb?'是':'否'}}</span>
              </template>
            </el-table-column>
            <el-table-column v-if="curType == 'ASSET_TYPE'" prop="tpmRate" label="点检频率" :formatter="formatterTpmRate"></el-table-column>

            <el-table-column label="操作" class-name="operate-col"> 
              <template #default="{row}">
                <el-button @click="handleEdit(row)" v-if="userRole != 'STATION_ADMIN'">修改</el-button>
                <el-button @click="handleSetCheckItem(row)" v-if="curType == 'ASSET_TYPE'">配置点检项</el-button>
                <el-button @click="handleQRCode(row)" v-if="curType == 'EMP_STATION'">二维码</el-button>
                <el-button @click="handleDelete(row)" class="del" v-if="userRole != 'STATION_ADMIN'">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="pagination-box" v-if="curType != 'AB_SETUP'">
            <el-pagination
              @current-change="handleCurrentPage"
              :current-page="pagination.currentPage"
              :page-size="pagination.pageSize"
              layout="prev, pager, next"
              :total="pagination.total">
            </el-pagination>
          </div>

          <!-- AB套配置 -->
          <ab-setup v-if="curType == 'AB_SETUP'" />
        </div>
      </div>
    </div>

    <!-- 修改弹框 -->
    <el-dialog v-model="editDialogVisible" width="500px" @close="cancelEdit('editFormRef')">
      <template #title>
        <h1>{{curEditLabel.zh}}</h1>
        <p>{{curEditLabel.en}}</p>
      </template>
      <el-form :model="editFormData" ref="editFormRef" class="edit-form" :rules="editFormRules">
        <el-form-item label="类型编号" prop="value">
          <el-input v-model="editFormData.value" disabled placeholder="编号将自动生成"></el-input>
        </el-form-item>
        <el-form-item label="类型名称" prop="label">
          <el-input v-model="editFormData.label" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="工段" prop="WORK_STATION" v-if="curType == 'CLASS_GROUP' || curType == 'EMP_STATION'">
          <el-select v-model="editFormData.WORK_STATION" @change="changeWorkStation" placeholder="请选择">
            <el-option
              v-for="item in workStationList"
              :key="item.value"
              :label="item.label"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="班组" prop="CLASS_GROUP" v-if="curType == 'EMP_STATION'">
          <el-select v-model="editFormData.CLASS_GROUP" placeholder="请选择">
            <el-option
              v-for="item in classGroupList"
              :key="item.value"
              :label="item.label"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否虚拟工段" prop="virtualFlag" v-if="curType == 'WORK_STATION'">
          <el-radio-group v-model="editFormData.virtualFlag">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="绑定资产类型" prop="virtualAssetType" v-if="editFormData.virtualFlag">
          <el-select v-model="editFormData.virtualAssetType" placeholder="请选择">
            <el-option
                v-for="item in assetTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item v-if="curType == 'ASSET_TYPE'" label="是否首页显示" prop="show">
          <el-radio-group v-model="editFormData.show">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="curType == 'ASSET_TYPE'" label="是否显示PTS" prop="showTpm">
          <el-radio-group v-model="editFormData.showTpm">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="curType == 'ASSET_TYPE'" label="A/B套工具" prop="isAb">
          <el-radio-group v-model="editFormData.isAb">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="curType == 'ASSET_TYPE'" label="点检频率" prop="tpmRate">
          <el-select v-model="editFormData.tpmRate" placeholder="请选择">
            <el-option
              v-for="item in tpmRateList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="curType == 'ASSET_TYPE'" label="点检类型" prop="tpmRate">
          <el-select v-model="editFormData.tpmBizType" placeholder="请选择">
            <el-option
                v-for="item in tpmBizTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="curType == 'ASSET_STATUS'" label="是否点检" prop="needTpm">
          <el-radio-group v-model="editFormData.needTpm">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="curType == 'ASSET_STATUS'" label="是否累计拧紧次数" prop="needAssetPts">
          <el-radio-group v-model="editFormData.needAssetPts">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="curType == 'ASSET_STATUS'" label="点检任务归属人" prop="tpmTaskBelong">
          <el-radio-group v-model="editFormData.tpmTaskBelong">
            <el-radio label="STAFF">普通工位</el-radio>
            <el-radio label="CLASS_LEADER">班长工位</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="curType == 'EMP_STATION'" label="工位类型" prop="empStationType">
          <el-radio-group v-model="editFormData.empStationType">
            <el-radio label="STAFF">普通工位</el-radio>
            <el-radio label="CLASS_LEADER">班长工位</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="cancelEdit('editFormRef')">取 消</el-button>
        <el-button class="active" @click="confirmEdit('editFormRef')">确 认</el-button>
      </template>
    </el-dialog>

    <!-- 配置点检项弹框 -->
    <el-dialog v-model="checkItemDialogVisible" width="1200px" :show-close="false">
      <template #title>
        <h1>配置点检项（{{checkItemObj.label}}）</h1>
        <p>Configuration check items</p>
        <el-button class="add-capacity" icon="el-icon-plus" @click="addCheckItem">新增</el-button>
      </template>
      <el-table :data="checkItemObj.data">
        <el-table-column label="序号" type="index" :index="index => index + 1"></el-table-column>
        <el-table-column prop="itemName" label="点检项" show-overflow-tooltip></el-table-column>
        <el-table-column prop="itemPoints" label="检查点"></el-table-column>
        <el-table-column prop="tpmMethod" label="点检方法"></el-table-column>
        <el-table-column prop="tpmStatus" label="状态"></el-table-column>
        <el-table-column prop="tpmRate" label="点检频次"></el-table-column>
        <el-table-column prop="sort" label="排序"></el-table-column>
        <el-table-column prop="formType" label="表单类型" :formatter="formatterFormType"></el-table-column>
        <el-table-column prop="inputStandardValue" label="标准值"></el-table-column>
        <el-table-column label="操作" width="120" class-name="operate-col">
          <template #default="{row}">
            <el-button @click="editCheckItem(row)">修改</el-button>
            <el-button @click="delCheckItem(row)" class="del">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <template #footer>
        <el-button @click="checkItemDialogVisible = false">关 闭</el-button>
      </template>

      <!-- 编辑点检项弹框 -->
      <el-dialog v-model="editCheckItemDialogVisible" width="600px" append-to-body>
        <template #title>
          <h1>{{curEditCheckItemLabel.zh}}</h1>
          <p>{{curEditCheckItemLabel.en}}</p>
        </template>
        <el-form :model="checkItemEditFormData" ref="checkItemEditFormRef" class="edit-form" :rules="checkItemEditFormRules">
          <el-form-item label="输入名称" prop="itemName">
            <el-input v-model="checkItemEditFormData.itemName" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="检查点" prop="itemPoints">
            <el-input v-model="checkItemEditFormData.itemPoints" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="点检方法" prop="tpmMethod">
            <el-input v-model="checkItemEditFormData.tpmMethod" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="状态" prop="tpmStatus">
            <el-input v-model="checkItemEditFormData.tpmStatus" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="点检频次" prop="tpmRate">
            <el-input v-model="checkItemEditFormData.tpmRate" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="排序" prop="sort">
            <el-input v-model="checkItemEditFormData.sort" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="表单类型" prop="formType">
            <el-select v-model="checkItemEditFormData.formType" placeholder="请选择" @change="() => checkItemEditFormData.inputStandardValue =''">
              <el-option v-for="item in formTypeList" :value="item.value" :label="item.label" :key="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="标准值" prop="inputStandardValue" v-if="checkItemEditFormData.formType == 'INPUT'">
            <el-input v-model="checkItemEditFormData.inputStandardValue" placeholder="请输入"></el-input>
          </el-form-item>
        </el-form>
        <template #footer>
          <el-button @click="cancelEditCheckItem('checkItemEditFormRef')">取 消</el-button>
          <el-button class="active" @click="confirmEditCheckItem('checkItemEditFormRef')">保 存</el-button>
        </template>
      </el-dialog>
    </el-dialog>

    <!-- 二维码 -->
    <div v-show="false" ref="qrcodeContainer"></div>

    <!-- 设置换班时间点弹框 -->
    <el-dialog v-model="ABHandoverPointDialogVisible" width="500px" @close="cancelSetABHandoverPoint('ABHandoverPointFormRef')">
      <template #title>
        <h1>设置换班时间点</h1>
        <p>Set shift change time point</p>
      </template>
      <el-form :model="ABHandoverPointFormData" ref="ABHandoverPointFormRef" class="edit-form ab-handover-point" :rules="ABHandoverPointFormRules">
        <el-form-item label="AB套换班时间点" prop="value">
          <el-time-picker v-model="ABHandoverPointFormData.value" format="HH:mm:ss" value-format="HH:mm:ss" placeholder="请选择" />
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="cancelSetABHandoverPoint('ABHandoverPointFormRef')">取 消</el-button>
        <el-button class="active" @click="confirmSetABHandoverPoint('ABHandoverPointFormRef')">确 认</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { sys, common } from '@/api'
import QRCode from 'qrcodejs2'
const _ = require('lodash')
import {parseJwt} from '@/utils/common'
import AbSetup from './components/ab-setup'

export default {
  name: 'SysConfig',
  components: {'ab-setup': AbSetup},
  data() {
    return {
      curType: parseJwt(localStorage.getItem('wlzcToken').split(' ')[1]).role == 'STATION_ADMIN' ? 'ASSET_TYPE' : 'WORK_STATION', // 当前选中类型
      selectedData: [], // 选中的行
      tableData: [], // 表格数据
      pagination: { // 分页信息
        currentPage: 1,
        pageSize: 10,
        total: 0
      },

      curEditLabel: {
        zh: '',
        en: '',
      },
      editDialogVisible: false, // 修改弹框visible
      initEditFormData: { 
        value: '',
        label: '',
        show: true,
        showTpm: true,
        isAb: false,
        tpmRate: '',
        WORK_STATION: '',
        CLASS_GROUP: '',
        needTpm: false,
        tpmBizType: 'COMMON',
        virtualFlag: false,
        virtualAssetType: ''
      },
      editFormData: {...this.initEditFormData}, // 修改表单
      editFormRules: { // 修改表单的校验
        // value: { required: true, message: '请输入', trigger: 'blur' },
        label: { required: true, message: '请输入', trigger: 'blur' },
        show: { required: true, message: '请选择', trigger: 'change' },
        isShowTpm: { required: true, message: '请选择', trigger: 'change' },
        tpmRate: { required: true, message: '请选择', trigger: 'change' },
        WORK_STATION: { required: true, message: '请选择', trigger: 'change' },
        CLASS_GROUP: { required: true, message: '请选择', trigger: 'change' },
        needTpm: { required: true, message: '请选择', trigger: 'change' },
        tpmBizType: { required: true, message: '请选择', trigger: 'change' },
      },

      checkItemDialogVisible: false, // 点检项对话框visible
      checkItemObj: {}, // 点检项数据

      curEditCheckItemLabel: { // 当前点检项label
        zh: '',
        en: '',
      },
      initCheckItemEditFormData: { // 修改点检项表单数据
        itemName: '',
        itemPoints: '',
        tpmMethod: '',
        tpmStatus: '',
        tpmRate: '',
        sort: '',
        formType: 'SELECT',
        inputStandardValue: ''
      },
      editCheckItemDialogVisible: false, // 修改点检项弹框visible
      checkItemEditFormData: {...this.initCheckItemEditFormData }, // 修改点检项表单
      checkItemEditFormRules: { // 修改点检项表单的校验
        itemName: { required: true, message: '请输入', trigger: 'blur' },
        itemPoints: { required: true, message: '请输入', trigger: 'blur' },
        tpmMethod: { required: true, message: '请输入', trigger: 'blur' },
        tpmStatus: { required: true, message: '请输入', trigger: 'blur' },
        tpmRate: { required: true, message: '请输入', trigger: 'blur' },
        sort: { required: true, message: '请输入', trigger: 'blur' },
        formType: { required: true, message: '请选择', trigger: 'change' },
        inputStandardValue: { required: true, message: '请输入', trigger: 'blur' },
      },
      tpmRateList: [], // 点检频率列表
      formTypeList: [
        {
          label: '选择',
          value: 'SELECT'
        },
        {
          label: '输入',
          value: 'INPUT'
        }
      ],
      tpmBizTypeList: [
        {
          label: '标准',
          value: 'COMMON'
        },
        {
          label: '灭火器',
          value: 'MHQ'
        }
      ],

      workStationList: [], // 工段列表
      classGroupList: [], // 班组列表

      curABHandoverPoint: '', // 当前换班时间点
      ABHandoverPointDialogVisible: false, // 设置换班时间点弹框
      ABHandoverPointFormData: { // 设置换班时间点表单
        value: ''
      }, 
      ABHandoverPointFormRules: { // 设置换班时间点表单校验
        value: { required: true, message: '请选择', trigger: 'change' },
      },
      assetTypeList: []
    }
  },
  computed: {
    userRole() {
      if(localStorage.getItem('wlzcToken')) {
        return parseJwt(localStorage.getItem('wlzcToken').split(' ')[1]).role
      }
      return ''
    },
  },
  mounted() {
    this.loadTableData()
    this.getTpmRateList()
    this.getDict()
  },
  methods: {
    // 获取列表数据
    async loadTableData() {
      let params = {
        type: this.curType,
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize
      }
      const {code, msg, data = [], total = 0} = await sys.getDictTypeList(params)
      if (code === 0) {
        this.tableData = data
        this.pagination.total = total
      } else {
        this.$message.error(msg)
      }
    },
    // 改变当前页
    handleCurrentPage(currentPageNo) {
      this.pagination.currentPage = currentPageNo
      this.loadTableData()
    },
    // 格式化表单类型
    formatterFormType(row, column, cellValue) {
      let label = cellValue
      for(let item of this.formTypeList) {
        if(item.value == cellValue){
          label = item.label
          continue
        }
      }
      return label
    },
    // 改变选中类型
    changeType(type) {
      this.curType = type

      if(type == 'AB_SETUP') {
        this.queryABHandoverPoint()
        return
      }
      
      this.pagination.currentPage = 1
      this.selectedData = []
      this.$refs.multipleTable.clearSelection()
      if(type == 'CLASS_GROUP') {
        this.getWorkStationList()
      }
      if(type == 'EMP_STATION') {
        this.getWorkStationClassGroupList()
      }

      if(type == 'WORK_STATION'){
        this.getDict()
      }
      this.loadTableData()
    },
    // 获取工段列表
    async getWorkStationList() {
      const {code, msg, data} = await common.queryDictWorkStation()
      if (code === 0) {
        this.workStationList = data || []
      } else {
        this.$message.error(msg)
      }
    },
    // 获取工段-班组列表
    async getWorkStationClassGroupList() {
      const {code, msg, data} = await common.queryWorkStationDictClassGroup()
      if (code === 0) {
        this.workStationList = data || []
        this.classGroupList = data[0] && data[0].children || []
      } else {
        this.$message.error(msg)
      }
    },
    // 改变工位
    changeWorkStation(wsVal){
      this.editFormData.CLASS_GROUP = ''
      let curWS = this.workStationList.filter(item => item.id == wsVal)[0]
      this.classGroupList = curWS && curWS.children
    },
    // 获取点检频率列表
    async getTpmRateList() {
      let types = 'TPM_RATE'
      const {code, msg, data = {}} = await common.queryDict({types})
      if (code === 0) {
        this.tpmRateList = data.TPM_RATE || []
      } else {
        this.$message.error(msg)
      }
    },
    // 点击新增
    handleAdd() {
      this.editFormData = this.initEditFormData
      this.curEditLabel.zh = '新增'
      this.curEditLabel.en = 'Add'
      this.editDialogVisible = true
    },
    // 点击修改
    handleEdit(row) {
      this.editFormData = {
        ...row,
        show: row.isShow,
        showTpm: row.isShowTpm,
        WORK_STATION: this.curType == 'CLASS_GROUP' ? row.parentId : this.curType == 'EMP_STATION' ? row.grandParentId : '',
        CLASS_GROUP: this.curType == 'EMP_STATION' ? row.parentId : ''
      }
      this.curEditLabel.zh = '修改'
      this.curEditLabel.en = 'Edit'
      this.editDialogVisible = true
    },
    // 确认修改
    confirmEdit(formName) {
      this.$refs[formName] && this.$refs[formName].validate && this.$refs[formName].validate(async (valid) => {
        if(valid) {
          let params = {
            ...this.editFormData,
            type: this.curType
          }
          if(this.curType == 'CLASS_GROUP') {
            params.parentId = this.editFormData.WORK_STATION
          }
          if(this.curType == 'EMP_STATION'){
            params.parentId = this.editFormData.CLASS_GROUP
          }

          const {code, msg} = await sys.saveDict(params)
          if(code === 0) {
            this.editDialogVisible = false
            this.$message.success(`${this.curEditLabel.zh}成功`)
            this.loadTableData()
          } else {
            this.$message.error(msg)
          }
        }
      })
    },
    // 取消修改
    cancelEdit(formName) {
      this.$refs[formName].resetFields()
      this.editDialogVisible = false
    },
    // 点击删除
    handleDelete({id}) {
      this.$confirm('此操作将删除该项, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const {code, msg} = await sys.delDict({id})
        if(code === 0) {
          this.$message.success(`删除成功`)
          this.loadTableData()
        } else {
          this.$message.error(msg)
        }
      }).catch(() => {
        console.info('取消删除操作')
      })
    },
    // 查询点检项列表
    async loadCheckItemTableData(row) {
      const {code, msg, data } = await sys.getCheckItemList(row.value)
      if(code === 0) {
        this.checkItemObj = {
          label: row.label,
          value: row.value,
          data: data || []
        }
      } else {
        this.$message.error(msg)
      }
    },
    // 点击配置点检项
    async handleSetCheckItem(row) {
      this.loadCheckItemTableData(row)
      this.checkItemDialogVisible = true
    },
    // 新增点检项
    addCheckItem() {
      this.checkItemEditFormData = {
        ...this.initCheckItemEditFormData,
        type: this.checkItemObj.value
      }

      this.curEditCheckItemLabel.zh = '新增点检项'
      this.curEditCheckItemLabel.en = 'Add check item'
      this.editCheckItemDialogVisible = true
    },
    // 编辑点检项
    editCheckItem(row) {
      this.checkItemEditFormData = {
        ...row,
        // type: this.checkItemObj.value
      }

      this.curEditCheckItemLabel.zh = '修改点检项'
      this.curEditCheckItemLabel.en = 'Edit check item'
      this.editCheckItemDialogVisible = true
    },
    // 取消修改点检项
    cancelEditCheckItem(formName) {
      this.$refs[formName].resetFields()
      this.editCheckItemDialogVisible = false
    },
    // 确认修改点检项
    confirmEditCheckItem(formName) {
      this.$refs[formName] && this.$refs[formName].validate && this.$refs[formName].validate(async (valid) => {
        if(valid) {
          const {code, msg} = await sys.saveCheckItem(this.checkItemEditFormData)
          if(code === 0) {
            this.editCheckItemDialogVisible = false
            this.$message.success(`${this.curEditCheckItemLabel.zh}成功`)
            this.loadCheckItemTableData(this.checkItemObj)
          } else {
            this.$message.error(msg)
          }
        }
      })
    },
    // 删除点检项
    delCheckItem({id}) {
      this.$confirm('此操作将删除该项, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const {code, msg} = await sys.delCheckItem({id})
        if(code === 0) {
          this.$message.success(`删除成功`)
          this.loadCheckItemTableData(this.checkItemObj)
        } else {
          this.$message.error(msg)
        }
      }).catch(() => {
        console.info('取消删除操作')
      })
    },
    // 格式化“是否点检”
    formatterNeedTpm(row, column, cellValue) {
      if(cellValue == true) {
        return '是'
      } 
      if(cellValue == false) {
        return '否'
      }
      return cellValue
    },
    formatterVirtualFlag(row, column, cellValue) {
      if(cellValue == true) {
        return '是'
      }
      if(cellValue == false) {
        return '否'
      }
      return cellValue
    },
    // 格式化“是否点检”
    formatterNeedAssetPts(row, column, cellValue) {
      if(cellValue == true) {
        return '是'
      }
      if(cellValue == false) {
        return '否'
      }
      return cellValue
    },
    // 格式化“是否首页显示”
    formatterIsShow(row, column, cellValue) {
      if(cellValue == true) {
        return '是'
      } 
      if(cellValue == false) {
        return '否'
      }
      return cellValue
    },
    // 格式化“是否显示PTS”
    formatterIsShowPts(row, column, cellValue) {
      if(cellValue == true) {
        return '是'
      } 
      if(cellValue == false) {
        return '否'
      }
      return cellValue
    },
    formatterEmpStationType(row, column, cellValue){
      if(cellValue == 'STAFF'){
        return '普通工位'
      } else {
        return '班长工位'
      }
    },
    formatterTpmTaskBelong(row, column, cellValue){
      if(cellValue == 'STAFF'){
        return '普通员工'
      } else {
        return '班长'
      }
    },
    // 格式化点检频率
    formatterTpmRate(row, column, cellValue) {
      let label = cellValue
      for(let item of this.tpmRateList) {
        if(item.value == cellValue){
          label = item.label
          continue
        }
      }
      return label
    },
    // 生成二维码
    setQRCode(code) {
      new QRCode(this.$refs.qrcodeContainer, {
        width: 100,// 二维码的宽
        height: 100,// 二维码的高
        text: code, // 二维码的内容
        colorDark: '#000',// 二维码的颜色
        colorLight: '#fff',
        correctLevel : QRCode.CorrectLevel.H
      })
    },
    // 点击“二维码”
    handleQRCode({grandParentLabel, parentLabel, value, label}) {
      this.$refs.qrcodeContainer.innerHTML = '' // 清空二维码
      this.setQRCode(`${value}`)

      let canvasData = this.$refs.qrcodeContainer.getElementsByTagName('canvas')
      let aLink = document.createElement('a')
      aLink.download = `${grandParentLabel}-${parentLabel}-${label}.png`
      aLink.href = canvasData[0].toDataURL("image/png")
      document.body.appendChild(aLink)
      aLink.click()
      document.body.removeChild(aLink)
    },
    // 批量下载二维码
    handleMultiQRCode() {
      if(this.selectedData.length === 0) {
        this.$message.warning('请选择需要下载的数据！') 
        return
      } 
      const map = new Map()
      let result = this.selectedData.filter(item => !map.has(item.id) && map.set(item.id, 1))
      if(result.length > 10) {
        this.$message.warning('不可超过10条数据')
      } else {
        result.forEach(item => {
          this.handleQRCode(item)
        })
      }
    },
    handleSelect(selection, row) {
      // 如果row在selection里面→增，如果row不在selection里面→减
      let selectionIncludeRow = selection.some(item => item.id === row.id)
      if(selectionIncludeRow) {// 增
        let alreadyExist = false
        for(let i = 0; i < this.selectedData.length; i++) {
          if(this.selectedData[i].id == row.id) {
            alreadyExist = true
            return
          }
        }
        if(!alreadyExist) {
          this.selectedData.push(row)
        }
      } else { // 减
        if(selection.length > 0) {
          this.selectedData = this.selectedData.filter(item => item.id !== row.id)
        } else {
          this.selectedData = []
        }
      } 
    },
    /**
     * 全选
     */
    handleSelectAll(selection){
      this.selectedData = _.merge([], selection)
    },

    // 获取当前换班时间点
    async queryABHandoverPoint() {
      const {code, msg, data} = await sys.getABHandoverPoint()
      if(code === 0) {
        this.curABHandoverPoint = data
      } else {
        this.$message.error(msg)
      }
    },
    // 点击设置换班时间点
    handleABHandoverPoint() {
      this.ABHandoverPointFormData = {
        ...this.ABHandoverPointFormData,
        value: this.curABHandoverPoint
      }
      this.ABHandoverPointDialogVisible = true
    },
    // 确认设置换班时间点
    confirmSetABHandoverPoint(formName) {
      this.$refs[formName] && this.$refs[formName].validate && this.$refs[formName].validate(async (valid) => {
        if(valid) {
          let params = {...this.ABHandoverPointFormData}
          const {code, msg} = await sys.setABHandoverPoint(params)
          if(code === 0) {
            this.ABHandoverPointDialogVisible = false
            this.$message.success(`操作成功`)
            this.queryABHandoverPoint()
          } else {
            this.$message.error(msg)
          }
        }
      })
    },
    // 取消设置换班时间点
    cancelSetABHandoverPoint(formName) {
      this.$refs[formName].resetFields()
      this.ABHandoverPointDialogVisible = false
    },
    // 获取字典数据
    async getDict() {
      let types = 'ASSET_TYPE'
      const {code, msg, data = {}} = await common.queryDict({types})
      if (code === 0) {
        this.assetTypeList = data.ASSET_TYPE || []
      } else {
        this.$message.error(msg)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.sys-config {
  height: 100%;
  .search-box {
    height: 100%;
    .body {
      .btn-box {
        .el-button {
          min-width: 170px;
          & + .el-button {
            margin-left: 30px;
          }
        }
      }
      .list-box {
        background: none;
        box-shadow: none;
        padding: 0;
        .global-operate-block {
          .global-operate-btn-box {
            .set-ab-handover-point {
              margin-left: 20px;
            }
          }
        }
      }
    }
  }

  .edit-form {
    background: linear-gradient(180deg, #27273A 0%, #191924 100%);
    border-radius: 16px;
    padding: 30px 30px 10px;
    .el-form-item {
      ::v-deep .el-form-item__label{
        width: 110px;
      }
      ::v-deep .el-date-editor--time {
        .el-input__prefix {
          .el-input__icon {
            bottom: 0;
          }
        }
      }
    } 

    &.ab-handover-point {
      .el-form-item {
        ::v-deep .el-form-item__label{
          width: 130px;
        }
      }
    }
  }

  ::v-deep .el-dialog {
    .add-capacity {
      position: absolute;
      right: 32px;
      top: 32px;
    }
  }
}
.edit-form {
  .el-form-item {
    ::v-deep .el-form-item__label{
      width: 82px;
      color: #B4B4C5;
    }
    ::v-deep .el-form-item__content {
      .el-select {
        width: 100%;
      }
    }
  }
}
</style>